import { Pivot, PivotItem } from '@fluentui/react';
import { useState, useEffect, } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider'
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/Documents/Edit';
import { DangerMessage, SuccesMessage , PrimaryMessage} from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate } from '../../../Functions/Configuration/Documents/DocumentStore';
import { OmniSelect } from '../../Shared/Controls/Select/OmniSelect';
//import { GetBranchsAsync } from '../../../Functions/Branchs/BranchStore';
import {GetBranchesByStatusAsync, SetAxiosPrivate as setAxiosPrivateBranches} from '../../../Functions/BranchesFrom365/BranchesFrom365Store'
import {SetAxiosPrivate as setAxiosPrivateDocumentType, GetToAutocomplete} from '../../../Functions/Catalogs/DocumentTypes/DocumentTypeStore';
import { OmniSwitch } from '../../Shared/Controls/Switch/OmniSwitch';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Document.svg').default
}

export default function Edit() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { register, handleSubmit, getValues, setValue } = useForm();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton } = useGeneralStatesContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();
    const [document, setDocument] = useState([]);
    const[showIsFiscal, setShowIsFiscal]=useState(false);
    SetAxiosPrivate(axiosPrivate);
    setAxiosPrivateDocumentType(axiosPrivate);
    setAxiosPrivateBranches(axiosPrivate);
    
    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };
    const [branches, setListBranch] = useState();
    const[documentTypes, setDocumentTypes]=useState();


    const getById = async () => {
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setShowIsFiscal(result.data.isFiscal);
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
                var data = getValues();
                var branchId=data.branchId;
                data.id = id;
                var branchId=data.branchId;
                data.cuvikBranchId=branchId.split("#")[0];
                let branch=branches.find(b=>parseInt(b.key)==parseInt(data.cuvikBranchId))
                data.cuvikBranchKey=branch!==undefined?branch.brachKey:"";
                data.visibleInMatrix=document.visibleInMatrix;
                data.isFiscal=document.isFiscal;
                let result = await UpdateAsync(id, data);
                if (result.isSuccessful) {
                    changeComponent(<Detail />, id);
                    updateItem({ ...data, acronym: localStorage.getItem("userName"), description:document.serie+' '+document.documentTypeDescription });
                    setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
                }
                else {
                    setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
                }
            }
    }
 
    useEffect(() => {
        const fillData = async () => {
            try {
                const data = await getById();            
                var complexBranchKey=data?.branchId+"#"+data?.cuvikBranchKey;
                setDocument({
                    serie:data?.serie,
                    documentTypeDescription:data?.documentTypeDescription,
                    branchId:data?.branchId,
                    cuvikBranchKey:data?.cuvikBranchKey,
                    isFiscal:data?.isFiscal,
                    visibleInMatrix:data?.visibleInMatrix,
                });
                setLoadingSkeleton(false);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        setLoadingSkeleton(true);
        fillData();
        const GetBranchs = async () => {
            //let result = await GetBranchsAsync();
            let result = await GetBranchesByStatusAsync();
            let branches=[];
            if (result.isSuccessful) {
                let branchesList=[];
                result.data.map((branch)=>{
                    let branchNew={
                        key: branch.id,
                        value: branch.id,
                        text:branch.identifier+" - "+branch.alias,
                        brachKey: "",
                    }
                    branchesList.push(branchNew);
                })
                setListBranch(branchesList);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
        GetBranchs();
        const GetDocumentTypes = async () => {
            let result = await GetToAutocomplete();
            if (result.isSuccessful) {
                setDocumentTypes(result.data);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
        GetDocumentTypes();
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client" >
                    <PivotItem headerText="Información" itemKey="information" >
                        <div className='container-fluid container-scroll-two-bars'>
                    <div className='row'>
                        <div className='col-md-auto'>
                            <OmniCard title='Datos generales'>
                                <div className='row'>
                                    {
                                        document.length !== 0 &&
                                        (
                                            <>
                                                 <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Tipo de documento' value={document.documentTypeDescription} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                        <OmniLabel text='Serie' value={document.serie} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            {
                                                branches?
                                                <OmniSelect required width='100%' title='Sucursal' register={{ ...register('branchId') }} items={branches} value={document.branchId} />
                                                :
                                                <OmniSelect required disabled width='100%' title='Sucursal' register={{ ...register('branchId') }} items={null} />
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniSwitch
                                                start
                                                label={"Documento es visible en matriz"}
                                                edit={setDocument}
                                                propertyId="visibleInMatrix"
                                                checked={document.visibleInMatrix}
                                            />
                                        </div>
                                    </div>
                                        {
                                            showIsFiscal==true
                                            ? 
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniSwitch
                                                        start
                                                        label={"Es fiscal"}
                                                        edit={setDocument}
                                                        propertyId="isFiscal"
                                                        checked={document.isFiscal}
                                                    />
                                                </div>
                                            </div>
                                            :<></>
                                        }
                                   
                                            </>
                                        )
                                    }
                                </div>
                            </OmniCard>
                        </div>
                    </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={update}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    )
}

