import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from "../../../Shared/Controls/Label/OmniLabel";

export default function TaxData(props){
    return(
        <div className='container-fluid container-scroll-two-bars'>
            <div className="row">
                <div className="col-lg-6 col-md-6 ">
                <OmniCard title='Datos generales'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <OmniLabel text='Código Postal' value={props.supplier.postalCode} />  
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <OmniLabel text='País' value='MÉXICO'/>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <OmniLabel text='Estado' value={props.supplier.state} />
                        </div>
                        <div className='col-lg-12 col-md-12'>
                            <OmniLabel text='Municipio' value={props.supplier.municipality} />
                        </div>
                        <div className='col-lg-12 col-md-12'>
                            <OmniLabel text='Colonia' value={props.supplier.colony} />
                        </div>
                        <div className='col-lg-12 col-md-12'>
                            <OmniLabel text='Localidad' value={props.supplier.location} />
                        </div>
                        <div className='col-lg-12 col-md-12'>
                            <OmniLabel text='Calle' value={props.supplier.address} />
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <OmniLabel text='Número exterior' value={props.supplier.outdoorNumber} />
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <OmniLabel text='Número interior' value={props.supplier.interiorNumber} />
                        </div>
                    </div>
                </OmniCard>
                </div>
                <div className="col-lg-6 col-md-6">
                <OmniCard title='Especificaciones para compras'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <OmniLabel text='Método de cálculo' value={props.supplier.calculationMethodDescription} />  
                        </div>
                        <div className='col-lg-12 col-md-12'>
                            <OmniLabel text='Desglosa impuestos' value={props.supplier.breakDownTaxes?"Si":"No"} />  
                        </div>
                        <div className='col-lg-12 col-md-12'>
                            <OmniLabel text='Utiliza costo con nota de crédito en compras' value={props.supplier.useCostWithNC?"Si":"No"}/>  
                        </div>
                        <div className='col-lg-12 col-md-12'>
                            <OmniLabel text='Factura con impuestos (USA Tax)' value={props.supplier.invoiceWithTax?"Si":"No"} />  
                            </div>
                        <div className='col-lg-12 col-md-12'>
                            <OmniLabel text='Retiene ISR (RESICO)' value={props.supplier.withholdsISR?"Si":"No"} />  
                        </div>
                    </div>
                </OmniCard>
                </div>
            </div>
        </div>
    );
}