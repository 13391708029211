import { Pivot, PivotItem } from '@fluentui/react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider'
import { useAccountingConfigurationContext } from '../../../Context/Account/AccountConfigurationProvider';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider";
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox';
import { OmniDropdown } from '../../Shared/Controls/Dropdown/OmniDropdown';
import { OmniSwitch } from '../../Shared/Controls/Switch/OmniSwitch';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Configurations/Warehouse/Edit';
import { DangerMessage, SuccesMessage, PrimaryMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate } from '../../../Functions/Configuration/Warehouse/WarehouseStore';
import { GetBranchsAsync, } from '../../../Functions/Branchs/BranchStore';
import Detail from './Detail';
import { Weekdays } from '../../../Assets/Constants/Enums/Weekdays';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { AccountingAccount } from '../../Shared/AccountingAccount/AccountingAccount';
import { AccountingSystemEnum } from '../../../Assets/Constants/Enums/AccountingSystem';
import { removeAccountingFormat } from '../../../Functions/Accounting/AccountingAccount/AccountingAccountStore';
import "./../../../Assets/Styles/Configuration/Warehouses/Warehouse.css";
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Edit() {
    const fieldWatcher = useFieldChangeWatcherContext();
    const { handleSubmit } = useForm();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton, changePivotKey, setChangePivotKey } = useGeneralStatesContext();
    const { useAccounting, accountingSystem } = useAccountingConfigurationContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();
    const [warehouseInformation, setWarehouseInformation] = useState([]);
    const [listBranch, setListBranch] = useState();
    SetAxiosPrivate(axiosPrivate);
    const [showErrors, setShowErrors] = useState({
        clave: undefined,
        name: undefined,
    });

    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };
    const sortedWeekdays = Weekdays.sort((a, b) => a.number - b.number);
    const getById = async (dataBranchs) => {
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            if (result.data.cuvikBranchId) {
                result.data.cuvikBranch = dataBranchs.find((x) => x.key === result.data.cuvikBranchId).text;
            }
            result.data.listBools = sortedWeekdays.map(day => {
                const dayInfo = result.data.listDaysReceiveWarehouseResult.find(d => d.day == day.number);
                return dayInfo ? dayInfo.active : false;
            });
            if (accountingSystem != AccountingSystemEnum.MICROSIP)
            {
                result.data.accountingAccount = removeAccountingFormat(result.data.accountingAccount);
                result.data.accountingAccountCostSale = removeAccountingFormat(result.data.accountingAccountCostSale);

                result.data.accountingAccountSale0 = removeAccountingFormat(result.data.accountingAccountSale0);
                result.data.accountingAccountSale8 = removeAccountingFormat(result.data.accountingAccountSale8);
                result.data.accountingAccountSale16 = removeAccountingFormat(result.data.accountingAccountSale16);
                result.data.accountingAccountExemptSale = removeAccountingFormat(result.data.accountingAccountExemptSale);

                result.data.accountingAccountReturnSales0 = removeAccountingFormat(result.data.accountingAccountReturnSales0);
                result.data.accountingAccountReturnSales8 = removeAccountingFormat(result.data.accountingAccountReturnSales8);
                result.data.accountingAccountReturnSales16 = removeAccountingFormat(result.data.accountingAccountReturnSales16);
                result.data.accountingAccountReturnExemptSales = removeAccountingFormat(result.data.accountingAccountReturnExemptSales);

                result.data.accountingAccountPurchases0 = removeAccountingFormat(result.data.accountingAccountPurchases0);
                result.data.accountingAccountPurchases8 = removeAccountingFormat(result.data.accountingAccountPurchases8);
                result.data.accountingAccountPurchases16 = removeAccountingFormat(result.data.accountingAccountPurchases16);
                result.data.accountingAccountExemptPurchases = removeAccountingFormat(result.data.accountingAccountExemptPurchases);


                result.data.accountingAccountReturnPurchases0 = removeAccountingFormat(result.data.accountingAccountReturnPurchases0);
                result.data.accountingAccountReturnPurchases8 = removeAccountingFormat(result.data.accountingAccountReturnPurchases8);
                result.data.accountingAccountReturnPurchases16 = removeAccountingFormat(result.data.accountingAccountReturnPurchases16);
                result.data.accountingAccountReturnExemptPurchases = removeAccountingFormat(result.data.accountingAccountReturnExemptPurchases);
                //result.data.accountingAccountDiscountSales = removeAccountingFormat(result.data.accountingAccountDiscountSales);
            }
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const getBranchs = async () => {
        let result = await GetBranchsAsync();
        if (result.isSuccessful) {
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        let errorMessages = fieldWatcher.ShowRequiredControls();
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages)]);
        }
        else {
            var data = warehouseInformation;
            let errorMessages = [];
            if (data) {
                data.maximumBoxes = data.maximumBoxes && data.maximumBoxes !== ''
                    ? data.maximumBoxes.toString().replace(",", "")
                    : data.maximumBoxes;
                if (!data.name) {
                    errorMessages.push("El campo nombre es obligatorio");
                }
                if (!data.cuvikBranchId) {
                    errorMessages.push("El campo sucursal es obligatorio");
                }
                else if (data.cuvikBranchId) {
                    var branch = listBranch.find((x) => x.key == data.cuvikBranchId);
                    data.cuvikBranchKey = branch.brachKey;
                    data.useWarehouse = branch.useWarehouse;
                }
            }
            if (useAccounting) {
                if (!data.accountingAccount) { errorMessages.push("El campo almacén es obligatorio"); }
                if (!data.accountingAccountCostSale) { errorMessages.push("El campo costo de venta es obligatorio"); }

                if (!data.accountingAccountSale16) { errorMessages.push("El campo ventas tasa 16 es obligatorio"); }
                if (!data.accountingAccountSale0) { errorMessages.push("El campo ventas tasa 0 es obligatorio"); }

                if (!data.accountingAccountReturnSales16) { errorMessages.push("El campo devolución sobre ventas 16 es obligatorio"); }
                if (!data.accountingAccountReturnSales0) { errorMessages.push("El campo devolución sobre ventas 0 es obligatorio"); }

                if (!data.accountingAccountPurchases16) { errorMessages.push("El campo compras tasa 16 es obligatorio"); }
                if (!data.accountingAccountPurchases0) { errorMessages.push("El campo compras tasa 0 es obligatorio"); }

                if (!data.accountingAccountReturnPurchases16) { errorMessages.push("El campo devolución de compras 16 es obligatorio"); }
                if (!data.accountingAccountReturnPurchases0) { errorMessages.push("El campo devolución de compras 0 es obligatorio"); }
                //if (data.accountingAccount !== data.accountingAccountPurchases) { errorMessages.push("Los campos almacén y compras deben ser iguales"); }
            }
            if (errorMessages.length > 0) {
                setListMessageCatalog(listMessage => [...listMessage, new PrimaryMessage(errorMessages.map(x => x).join('. '))]);
            }
            else {
                if (useAccounting) {
                    data.accountingAccount = data.accountingAccount.endsWith(".") ? data.accountingAccount.slice(0, -1) : data.accountingAccount;
                    data.accountingAccountCostSale = data.accountingAccountCostSale.endsWith(".") ? data.accountingAccountCostSale.slice(0, -1) : data.accountingAccountCostSale;

                    data.accountingAccountSale16 = data.accountingAccountSale16.endsWith(".") ? data.accountingAccountSale16.slice(0, -1) : data.accountingAccountSale16;
                    data.accountingAccountSale0 = data.accountingAccountSale0.endsWith(".") ? data.accountingAccountSale0.slice(0, -1) : data.accountingAccountSale0;
                    data.accountingAccountReturnSales16 = data.accountingAccountReturnSales16.endsWith(".") ? data.accountingAccountReturnSales16.slice(0, -1) : data.accountingAccountReturnSales16;
                    data.accountingAccountReturnSales0 = data.accountingAccountReturnSales0.endsWith(".") ? data.accountingAccountReturnSales0.slice(0, -1) : data.accountingAccountReturnSales0;
                    data.accountingAccountPurchases16 = data.accountingAccountPurchases16.endsWith(".") ? data.accountingAccountPurchases16.slice(0, -1) : data.accountingAccountPurchases16;
                    data.accountingAccountPurchases0 = data.accountingAccountPurchases0.endsWith(".") ? data.accountingAccountPurchases0.slice(0, -1) : data.accountingAccountPurchases0;
                    data.accountingAccountReturnPurchases16 = data.accountingAccountReturnPurchases16.endsWith(".") ? data.accountingAccountReturnPurchases16.slice(0, -1) : data.accountingAccountReturnPurchases16;
                    data.accountingAccountReturnPurchases0 = data.accountingAccountReturnPurchases0.endsWith(".") ? data.accountingAccountReturnPurchases0.slice(0, -1) : data.accountingAccountReturnPurchases0;
                    
                    data.accountingAccountSale8 = data.accountingAccountSale8 ? data.accountingAccountSale8.endsWith(".") ? data.accountingAccountSale8.slice(0, -1) : data.accountingAccountSale8 : null;
                    data.accountingAccountExemptSale = data.accountingAccountExemptSale ? data.accountingAccountExemptSale.endsWith(".") ? data.accountingAccountExemptSale.slice(0, -1) : data.accountingAccountExemptSale : null;
                    data.accountingAccountReturnSales8 = data.accountingAccountReturnSales8 ? data.accountingAccountReturnSales8.endsWith(".") ? data.accountingAccountReturnSales8.slice(0, -1) : data.accountingAccountReturnSales8 : null;
                    data.accountingAccountReturnExemptSales = data.accountingAccountReturnExemptSales ? data.accountingAccountReturnExemptSales.endsWith(".") ? data.accountingAccountReturnExemptSales.slice(0, -1) : data.accountingAccountReturnExemptSales : null;
                    data.accountingAccountPurchases8 = data.accountingAccountPurchases8 ? data.accountingAccountPurchases8.endsWith(".") ? data.accountingAccountPurchases8.slice(0, -1) : data.accountingAccountPurchases8 : null;
                    data.accountingAccountExemptPurchases = data.accountingAccountExemptPurchases ? data.accountingAccountExemptPurchases.endsWith(".") ? data.accountingAccountExemptPurchases.slice(0, -1) : data.accountingAccountExemptPurchases : null;
                    data.accountingAccountReturnPurchases8 = data.accountingAccountReturnPurchases8 ? data.accountingAccountReturnPurchases8.endsWith(".") ? data.accountingAccountReturnPurchases8.slice(0, -1) : data.accountingAccountReturnPurchases8 : null;
                    data.accountingAccountReturnExemptPurchases = data.accountingAccountReturnExemptPurchases ? data.accountingAccountReturnExemptPurchases.endsWith(".") ? data.accountingAccountReturnExemptPurchases.slice(0, -1) : data.accountingAccountReturnExemptPurchases : null;
                    //data.accountingAccountDiscountSales = data.accountingAccountDiscountSales ? data.accountingAccountDiscountSales.endsWith(".") ? data.accountingAccountDiscountSales.slice(0, -1) : data.accountingAccountDiscountSales : null;
                }
                data.listDaysReceiveWarehouseResult = data.listBools;
                let result = await UpdateAsync(id, data);
                if (result.isSuccessful) {
                    changeComponent(<Detail />, id);
                    data.id = id;
                    updateItem({ ...data, acronym: localStorage.getItem("userName") });
                    setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
                }
                else {
                    setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
                }
            }
        }
    }
    useEffect(() => {
        const fillData = async () => {
            try {
                const dataBranchs = await getBranchs();
                const dataWarehouse = await getById(dataBranchs);
                setListBranch(dataBranchs);
                setWarehouseInformation(dataWarehouse);
                setLoadingSkeleton(false);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        setLoadingSkeleton(true);
        fillData();
    }, [])

    const [selectedKey, setSelectedKey] = useState();
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    const onBlurName = (event) => {
        ValidText(event, "name", "nombre");
    };
    const onBlurAccountingAccount = (event) => {
        ValidText(event, "accountingAccount", "almacén");
    }
    const onBlurAccountingAccountCostSale = (event) => {
        ValidText(event, "accountingAccountCostSale", "costo de venta");
    }
    const onBlurAccountingAccountSale0 = (event) => {
        ValidText(event, "accountingAccountSale0", "ventas tasa 0");
    }
    const onBlurAccountingAccountSale16 = (event) => {
        ValidText(event, "accountingAccountSale16", "ventas tasa 16");
    }
    const onBlurAccountingAccountReturnSales0 = (event) => {
        ValidText(event, "accountingAccountReturnSales0", "devolución sobre ventas 0");
    }
    const onBlurAccountingAccountReturnSales16 = (event) => {
        ValidText(event, "accountingAccountReturnSales16", "devolución sobre ventas 16");
    }
    const onBlurAccountingAccountPurchases0 = (event) => {
        ValidText(event, "accountingAccountPurchases0", "compras tasa 0");
    }
    const onBlurAccountingAccountPurchases16 = (event) => {
        ValidText(event, "accountingAccountPurchases16", "compras tasa 16");
    }
    const onBlurAccountingAccountReturnPurchases0 = (event) => {
        ValidText(event, "accountingAccountReturnPurchases0", "devolución de compras 0");
    }
    const onBlurAccountingAccountReturnPurchases16 = (event) => {
        ValidText(event, "accountingAccountReturnPurchases16", "devolución de compras 16");
    }
    function ValidText(event, property, fild) {
        let text = event.target.value.trim();
        if (text) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: `El campo ${fild} es obligatorio`
            }));
            event?.target?.focus();
        }
    }
    useEffect(() => {
        if (warehouseInformation) {
            setChangePivotKey(selectedKey);
        }
    }, [selectedKey]);
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                {
                    <Pivot className="pivot-center-client"
                        selectedKey={selectedKey}
                        onLinkClick={handleLinkClick} defaultSelectedKey={changePivotKey}
                        overflowBehavior="menu" styles={pivotStyles}
                    >
                        <PivotItem headerText="Información" itemKey="information" >
                            <div className='container-fluid container-scroll-two-bars'>
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Datos generales'>
                                            {warehouseInformation.length !== 0 && (<>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniLabel text='Clave' value={warehouseInformation.warehouseKey} />
                                                    </div>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            requiredOmni
                                                            defaultValue
                                                            width="85%"
                                                            title='Nombre'
                                                            maxLength={64}
                                                            property="name"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.name}
                                                            blur={onBlurName}
                                                            validationMessage={showErrors.name}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniCheckBox
                                                            title='Este almacén es bodega'
                                                            propertyId="isWarehouse"
                                                            edit={setWarehouseInformation}
                                                            propertyChecked={warehouseInformation?.isWarehouse}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        {listBranch ?
                                                            <OmniDropdown
                                                                requiredOmni
                                                                defaultValue
                                                                title='Sucursal'
                                                                items={listBranch}
                                                                property="cuvikBranchId" name="cuvikBranch"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.cuvikBranch}
                                                                selected={warehouseInformation?.cuvikBranchId}
                                                                validationMessage={showErrors.cuvikBranchId}
                                                            />
                                                            :
                                                            <OmniDropdown
                                                                requiredOmni
                                                                defaultValue
                                                                disabled
                                                                title='Sucursal'
                                                                items={null}
                                                                validationMessage={showErrors.cuvikBranchId} />
                                                        }
                                                    </div>
                                                </div>
                                            </>)}
                                        </OmniCard>
                                        {/*<OmniCard title='Resurtir almacén'>*/}
                                        {/*    {warehouseInformation.length !== 0 && (<>*/}
                                        {/*    <div className='row'>*/}
                                        {/*        <div className='col-lg-12 col-md-12'>*/}
                                        {/*            <OmniCheckBox title='Se resurten todos los artículos de este almacén' register={{ ...register('restockWarehouse') }}*/}
                                        {/*                propertyChecked={warehouseInformation.restockWarehouse} />*/}
                                        {/*        </div>*/}
                                        {/*        </div>*/}
                                        {/*    </>)}*/}
                                        {/*</OmniCard>*/}
                                    </div>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Capacidad de recibo'>
                                            {warehouseInformation.length !== 0 && (<>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            inputType="number"
                                                            length="4"
                                                            width="85%"
                                                            title='Recepciones por día'
                                                            property="dailyReceptions"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.dailyReceptions.toString()}
                                                        />
                                                    </div>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            inputType="number"
                                                            length="4"
                                                            width="85%"
                                                            title='Toneladas por día'
                                                            property="maximumTons"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.maximumTons.toString()}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            inputType="number"
                                                            length="4"
                                                            width="85%"
                                                            title='Volúmen por día'
                                                            property="maximumVolume"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.maximumVolume.toString()}
                                                        />
                                                    </div>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            inputType="number"
                                                            length="8"
                                                            width="85%"
                                                            title='Cajas por día'
                                                            property="maximumBoxes"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.maximumBoxes.toString()}
                                                        />
                                                    </div>
                                                </div>
                                            </>)}
                                        </OmniCard>
                                        <OmniCard title='Resurtido'>
                                            {warehouseInformation.length !== 0 && (<>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <OmniTextBox
                                                            inputType="number"
                                                            length="4"
                                                            width="85%"
                                                            title='Días de inventario'
                                                            property="inventoryDays"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.inventoryDays.toString()}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    {
                                                        Weekdays.map((day) => (
                                                            <div className='col-lg-4 col-md-12'>
                                                                <OmniSwitch
                                                                    label={day.name}
                                                                    propertyId="listBools"
                                                                    edit={setWarehouseInformation}
                                                                    itemList={day.number}
                                                                    checked={warehouseInformation.listBools[day.number]}
                                                                />
                                                            </div>
                                                        ))}
                                                </div>
                                            </>)}
                                        </OmniCard>
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                        {
                            useAccounting &&
                            <PivotItem headerText='Contabilidad' itemKey="contabilidad">
                                    <div className='container-fluid container-scroll-two-bars'>
                                    <div className='row'>
                                        <div className='col-md-auto'>
                                                <OmniCard title='Contabilidad' classCard='card-omni-warehouse'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <AccountingAccount
                                                            focus
                                                            //requiredOmni
                                                            //defaultValue
                                                            title='Almacén'
                                                            property="accountingAccount"
                                                            edit={setWarehouseInformation}
                                                            value={warehouseInformation?.accountingAccount}
                                                            blur={onBlurAccountingAccount}
                                                            validationMessage={showErrors.accountingAccount}
                                                        />
                                                    </div>
                                            </OmniCard>
                                        </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-auto'>
                                                <OmniCard title='Cuentas de ventas' classCard='card-omni-warehouse'>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                //requiredOmni
                                                                //defaultValue
                                                                title='Costo de venta'
                                                                property="accountingAccountCostSale"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountCostSale}
                                                                blur={onBlurAccountingAccountCostSale}
                                                                validationMessage={showErrors.accountingAccountCostSale}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                //requiredOmni
                                                                //defaultValue
                                                                title='Ventas tasa 16'
                                                                property="accountingAccountSale16"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountSale16}
                                                                blur={onBlurAccountingAccountSale16}
                                                                validationMessage={showErrors.accountingAccountSale16}
                                                            />
                                                        </div>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                //requiredOmni
                                                                //defaultValue
                                                                title='Ventas tasa 0'
                                                                property="accountingAccountSale0"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountSale0}
                                                                blur={onBlurAccountingAccountSale0}
                                                                validationMessage={showErrors.accountingAccountSale0}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                title='Ventas tasa 8'
                                                                property="accountingAccountSale8"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountSale8}
                                                                validationMessage={showErrors.accountingAccountSale8}
                                                            />
                                                        </div>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                title='Ventas exentos'
                                                                property="accountingAccountExemptSale"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountExemptSale}
                                                                validationMessage={showErrors.accountingAccountExemptSale}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                //requiredOmni
                                                                //defaultValue
                                                                title='Devolución sobre ventas 16'
                                                                property="accountingAccountReturnSales16"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountReturnSales16}
                                                                blur={onBlurAccountingAccountReturnSales16}
                                                                validationMessage={showErrors.accountingAccountReturnSales16}
                                                            />
                                                        </div>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                //requiredOmni
                                                                //defaultValue
                                                                title='Devolución sobre ventas 0'
                                                                property="accountingAccountReturnSales0"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountReturnSales0}
                                                                blur={onBlurAccountingAccountReturnSales0}
                                                                validationMessage={showErrors.accountingAccountReturnSales0}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                title='Devolución sobre ventas 8'
                                                                property="accountingAccountReturnSales8"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountReturnSales8}
                                                                validationMessage={showErrors.accountingAccountReturnSales8}
                                                            />
                                                        </div>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                title='Devolución sobre ventas exentos'
                                                                property="accountingAccountReturnExemptSales"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountReturnExemptSales}
                                                                validationMessage={showErrors.accountingAccountReturnExemptSales}
                                                            />
                                                        </div>
                                                    </div>
                                                </OmniCard>
                                            </div>
                                            <div className='col-md-auto'>
                                                <OmniCard title='Cuentas de compras' classCard='card-omni-warehouse'>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                //requiredOmni
                                                                //defaultValue
                                                                title='Compras tasa 16'
                                                                property="accountingAccountPurchases16"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountPurchases16}
                                                                blur={onBlurAccountingAccountPurchases16}
                                                                validationMessage={showErrors.accountingAccountPurchases16}
                                                            />
                                                        </div>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                //requiredOmni
                                                                //defaultValue
                                                                title='Compras tasa 0'
                                                                property="accountingAccountPurchases0"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountPurchases0}
                                                                blur={onBlurAccountingAccountPurchases0}
                                                                validationMessage={showErrors.accountingAccountPurchases0}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                title='Compras tasa 8'
                                                                property="accountingAccountPurchases8"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountPurchases8}
                                                                validationMessage={showErrors.accountingAccountPurchases8}
                                                            />
                                                        </div>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                title='Compras exentos'
                                                                property="accountingAccountExemptPurchases"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountExemptPurchases}
                                                                validationMessage={showErrors.accountingAccountExemptPurchases}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                //requiredOmni
                                                                //defaultValue
                                                                title='Devolución de compras 16'
                                                                property="accountingAccountReturnPurchases16"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountReturnPurchases16}
                                                                blur={onBlurAccountingAccountReturnPurchases16}
                                                                validationMessage={showErrors.accountingAccountReturnPurchases16}
                                                            />
                                                        </div>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                //requiredOmni
                                                                //defaultValue
                                                                title='Devolución de compras 0'
                                                                property="accountingAccountReturnPurchases0"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountReturnPurchases0}
                                                                blur={onBlurAccountingAccountReturnPurchases0}
                                                                validationMessage={showErrors.accountingAccountReturnPurchases0}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                title='Devolución de compras 8'
                                                                property="accountingAccountReturnPurchases8"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountReturnPurchases8}
                                                                validationMessage={showErrors.accountingAccountReturnPurchases8}
                                                            />
                                                        </div>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <AccountingAccount
                                                                title='Devolución de compras exentos'
                                                                property="accountingAccountReturnExemptPurchases"
                                                                edit={setWarehouseInformation}
                                                                value={warehouseInformation?.accountingAccountReturnExemptPurchases}
                                                                validationMessage={showErrors.accountingAccountReturnExemptPurchases}
                                                            />
                                                        </div>
                                                    </div>
                                                </OmniCard>
                                            </div>
                                        </div>
                                </div>
                            </PivotItem>
                        }
                        <PivotItem headerText="Cambios" itemKey="changes" headerButtonProps={{
                            'disabled': true,
                            'style': { color: 'grey' }
                        }}>
                        </PivotItem>
                    </Pivot>
                }
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={update}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }</>
    );
}